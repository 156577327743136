export default function Schedule() {
  return (
    <div className="row">
      <h1 id="schedule">Schedule</h1>
      <div className="col-12">
        <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="b21800872120" data-widget-version="1"></healcode-widget>
      </div>
    </div>
  );
}
